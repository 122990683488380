_cruxUtils._cruxLocale = {
"crux.no.bc.message":"Nenhuma vis\u00e3o geral dispon\u00edvel. Clique no registro para ver mais detalhes",
"crm.appointments.status.markcompleted1": "Marcar como conclu\u00eddo",
"crm.mass.actions.all.selected": "<span class=\"dIB vaM\">Todos os</span><span class='mL3 mR3 crm-font-bold dIB vaM recordCountHt'> {0}\u00a0</span><span class=\"dIB vaM\"> {1} nesta exibi\u00e7\u00e3o foram selecionados.</span>",
"crm.module.selectall": "Selecione Todos {0} nesta exibi\u00e7\u00e3o",
"records": "registros",
"crm.record.not.accessible": "Registro(s) n\u00e3o acess\u00edvel(is)",
"crm.view.calls.recording.err.msg": "A grava\u00e7\u00e3o de voz n\u00e3o tem um URL de \u00e1udio reproduz\u00edvel.",
"crm.listview.selected.records": "<span class='mL3 crm-small-font-size crm-font-bold vaM dIB'>{0} </span><span class=\"mL3 seletcedRecords\">\u00a0{1} selecionado.</span>",
"totalrecords": "Total de registros",
"crm.listview.options.text.clip": "Texto do clipe",
"crm.listview.options.text.wrap": "Quebra de texto autom\u00e1tica",
"crm.listview.options.reset.width": "Redefinir tamanho da coluna",
"crm.listview.options.manage": "Gerenciar colunas",
"crm.listview.customview.locked": "As colunas n\u00e3o podem ser personalizadas porque esta exibi\u00e7\u00e3o est\u00e1 bloqueada",
"crm.api.apidashboard.SortBy": "Classificar por",
"crm.privacy.listview.consent.locked": "O registro foi bloqueado para processamento",
"crm.label.reschedule.call": "Reagendar {0}",
"crm.button.edit": "Editar",
"Delete": "Excluir",
"crm.appointments.status.markcancel": "Cancelar {0}",
"crm.appointments.status.markreschedule": "Reagendar {0}",
"crm.label.cancel.call": "Cancelar {0}",
"crm.label.mark.completed": "Marcar como conclu\u00eddo",
"crm.listview.customview.locked": "As colunas n\u00e3o podem ser personalizadas porque esta exibi\u00e7\u00e3o est\u00e1 bloqueada",
"pf.deleted.state": "Estado exclu\u00eddo",
"crm.project.integration.title": "Zoho Projects",
"crm.integ.sync.message": "Sincronizar com {0}",
"crm.schedules.prefix.in": "Em",
"crm.record.record.locked.other": "O registro est\u00e1 bloqueado para outros usu\u00e1rios do perfil.",
"crm.record.lock.record.locked": "O registro est\u00e1 bloqueado.",
"crm.approvalProcess.label.waitingForApproval": "Aguardando aprova\u00e7\u00e3o",
"crm.reviewprocess.record.review.pending": "Pendente para revis\u00e3o",
"crm.approvalProcess.label.waitingForFindAndMerge": "A mesclagem das duplicatas est\u00e1 em andamento.",
"crm.privacy.listview.consent.locked": "O registro foi bloqueado para processamento",
"crm.zia.vision.processing": "A Zia est\u00e1 validando a(s) imagem(ns).",
"crm.zia.vision.rejected.msg": "O registro foi rejeitado devido \u00e0 falha na valida\u00e7\u00e3o da imagem.",
"crm.zia.vision.record.failure.msg": "O registro est\u00e1 aguardando aprova\u00e7\u00e3o devido a falha na valida\u00e7\u00e3o da imagem.",
"crm.alert.max.cvcols.reached": "Voc\u00ea pode selecionar um m\u00e1ximo de {0} colunas.",
"crm.label.small.tags": "Etiquetas",
"crm.label.edit.module": "Editar {0}",
"crm.social.integ.no.result": "Nenhum resultado encontrado",
"custommodule.crmfield.fl.name": "Nome {0}",
"Appointment": "Compromisso",
"crm.button.create.call": "Criar chamada",
"crm.module.create": "Criar {0}",
"crm.customview.activty.badge": "Emblema de atividade",
"crm.button.callnow": "Chamar agora",
"crm.manualcalllist.complete.call": "Registrar uma chamada",
"crm.label.schedule.call": "Agendar uma chamada",
"crm.filter.label.replied":"respondido",
"bytes": "bytes",
"crm.field.label.user.lookup": "Usu\u00e1rio",
"crm.translation.refresh.cases": "A a\u00e7\u00e3o que voc\u00ea est\u00e1 tentando realizar n\u00e3o p\u00f4de ser conclu\u00edda. Atualize a p\u00e1gina e tente novamente.",
"crm.project.sync.notes.info": "Somente os coment\u00e1rios das tarefas dos projetos associados ser\u00e3o mostrados",
"crux.note.content.limit.warning.msg":"Permitido salvar {0} caracteres apenas no conte\u00fado das notas",
"crux.note.formatting.options":"Op\u00e7\u00f5es de formata\u00e7\u00e3o",
"crm.label.required": "Obrigat\u00f3rio",
"crux.condition.not.belongs.to":"n\u00e3o pertence a {0}",
"crm.project.sync.notes.info": "Somente os coment\u00e1rios das tarefas dos projetos associados ser\u00e3o mostrados",
"crm.sf.permission.no.edit": "Voc\u00ea n\u00e3o tem permiss\u00e3o para atualizar entradas.",
"crm.sf.permission.no.create": "Voc\u00ea n\u00e3o tem permiss\u00e3o para adicionar entradas.",
"crm.sf.permission.no.delete": "Voc\u00ea n\u00e3o tem permiss\u00e3o para excluir entradas.",
"crux.note.this.note":"esta nota",
"KB": "KB",
"MB": "MB",
"current.logged.in.user.role": "Fun\u00e7\u00e3o do usu\u00e1rio conectado",
"Unit Price": "Pre\u00e7o Unit\u00e1rio",
"Qty in Stock": "Quantidade em estoque",
"crm.label.Product Code": "C\u00f3digo do Produto",
"TotalAfterDiscount": "Total ap\u00f3s desconto",
"crm.lookupfilter.entity.errormsg": "O {0} selecionado n\u00e3o satisfaz aos crit\u00e9rios de pesquisa.",
"crm.iamexception.maxlen": "Voc\u00ea n\u00e3o pode inserir mais de {0} caracteres para {1}",
"crm.record.locking.permission.denied": "Esta a\u00e7\u00e3o n\u00e3o pode ser executada, pois o registro est\u00e1 bloqueado.",
"List Price": "Pre\u00e7o de Lista",
"crm.label.no.more.records": "N\u00e3o h\u00e1 mais registros",
"crm.workflow.rule.created.dateAndTime":"{0} em {1} \u00e0s {2}",
"crux.new.note.msg":"O que significa esta anota\u00e7\u00e3o?",
"crm.image.uploaded":"Imagem carregada",
"crm.imageupload.drag.drop.here":"Arraste e solte as imagens aqui",
"crm.fileupload.drag.drop.here":"Arraste e solte os arquivos aqui",
"crm.fileupload.wait.msg":"Aguarde at\u00e9 que os arquivos selecionados sejam carregados",
"crm.fileupload.attach.fail":"Falha no anexo",
"crm.fileupload.support.format":"Formato de arquivo compat\u00edvel (jpeg, jpg, png, pdf) para o treinamento da Zia",
"custmr.prtl.user.role": "Usu\u00e1rio do portal",
"crm.subform.empty.alert.header":"Linha vazia n\u00e3o pode ser salva",
"crm.crud.lookup.module.inaccessible": "Voc\u00ea n\u00e3o tem permiss\u00e3o para acessar este m\u00f3dulo de pesquisa",
"crm.crud.lookup.inaccessible.record": "O registro n\u00e3o existe ou est\u00e1 inacess\u00edvel para voc\u00ea",
"crm.custombutton.valid.weburl.check": "Insira algum valor.",
"crm.label.subform.goto.top": "V\u00e1 para o topo da lista",
"crm.label.subform.row.show.all": "Mostrar tudo ({0})",
"cpq.pr.nodiscount": "Nenhum desconto associado",
"crm.inventory.lineitem.no.pricebook.new": "Nenhum {0} est\u00e1 associado.",
"crm.label.subform.allowedlimit": "O limite permitido de {0} linhas foi atingido",
"crm.reports.integ.okay": "OK",
"crm.crud.subform.deleted.record": "As linhas do subformul\u00e1rio atualizadas j\u00e1 foram exclu\u00eddas. Atualize a p\u00e1gina e tente novamente.",
"crux.criteria.fieldlabel.valid.check":"Insira um r\u00f3tulo de campo v\u00e1lido",
"crm.subform.delete.confirm.header":"Excluir linha do subformulário",
"crm.subform.delete.confirm.msg": "Tem certeza que deseja excluir a linha selecionada no {0}?",
"crm.subform.delete.tooltip.msg" :"A linha não pode ser excluída. Pelo menos uma entrada de linha para o {0} é necessária.",
	"crm.condition.belongs.to":"pertence ao {0}",//no i18n
"crm.condition.not.belongs.to":"n\u00e3o pertence ao {0}",//no i18n
"crm.orchestration.error.selectvalue": "Por favor, selecione um valor",
	"crm.attach.upload.image":"Anexar imagens",//no i18n
"crm.general.select.photo":"Selecionar imagem",//no i18n
"crm.imageupload.wait.msg":"Aguarde at\u00e9 as imagens selecionadas serem carregadas.",//no i18n
"crm.image.filepicker.desc.draganddrop":"Arraste e solte as imagens.",//no i18n
"crm.image.filepicker.desc.browse":"Clique para procurar imagens...",//no i18n
"crm.attach.upload.userinfo":"O tamanho total \u00e9 limitado a <span class",//no i18n
"crm.image.supported.formats":"Formatos suportados: JPEG, PNG, GIF e BMP.",//no i18n
"crm.imageupload.allowed.field.length":"Voc\u00ea pode fazer upload de no m\u00e1ximo {0} imagens.",//no i18n
"crm.general.crop.and.set":"Cortar e definir",//no i18n
"crm.attach.option.label":"Anexar",//no i18n
"crm.image.crop.and.rotate":"Cortar e girar",//no i18n
"crm.image.resolution":"Resolu\u00e7\u00e3o",//no i18n
"crm.attachment.size":"Tamanho",//no i18n
"crm.label.close":"Fechar",//no i18n
"crm.label.previous":"Anterior",//no i18n
"crm.label.next":"Pr\u00f3ximo",//no i18n
"crm.subform.record.create.maxrow.limit": "M\u00e1ximo de {0} registros permitidos no {1}",
"crm.label.subform.addrows": "Adicionar linha",
"crm.tax.association.check": "N\u00e3o h\u00e1 imposto associado a esse produto.",
	"crm.gallery.inventory.template.discount": "Desconto",
	"crm.inventory.discount.scheme.range": "Intervalo",
	"Description": "Descri\u00e7\u00e3o",
	"crm.inventory.lineitem.no.pricebook": "Nenhuma lista de pre\u00e7os est\u00e1 associada.",
	"crm.recordImage.previewimage":"Pr\u00e9-visualizar imagem",//no i18n
"crm.FileuploadField.addNewImage":"Fazer upload de imagem",//no i18n
"crm.fileuploader.removefile":"Remover",//no i18n
	"voc.gc.configure":"Configurar",//no i18n
"Edit":"Editar",//no i18n
	"crm.criteria.secondaryfield.valid.check" : "Forne\u00e7a o valor correspondente para {0}",
	"crm.label.field":"Campo",//no i18n
"crm.label.value":"Valor",//no i18n
	"crm.button.clone":"Clonar",//no i18n
	"sentiment.criteria.wrongcriteria":"O valor dos crit\u00e9rios n\u00e3o pode ser maior do que {0}",//no i18n
"crm.mb.field.common.splc":"Caracteres especiais n\u00e3o s\u00e3o permitidos. Insira um valor v\u00e1lido.",//no i18n
	"crm.view.attachment.download":"Download",//no i18n
"crm.label.view":"Visualizar",//no i18n
	"crm.label.field.plural":"campos",//no i18n
	"crm.label.in.minutes":"{0} (em minutos)",//no i18n
	"crm.security.roles.list":"Lista de fun\u00e7\u00f5es",//no i18n
"crm.security.roles.lookup.info":"Selecione uma fun\u00e7\u00e3o da lista.",//no i18n
"crm.territory.addterritory":"Adicionar territ\u00f3rio",//no i18n
"crm.title.edit.territory":"Editar territ\u00f3rio",//no i18n
"crm.territory.title.assign.territories":"Atribuir territ\u00f3rios",//no i18n
	"crm.label.context.help":"Ajuda",//no i18n
	"crm.label.from":"De",//no i18n
"crm.label.to":"Para",//no i18n
	"workflow.option.webhookFailure.fromDate":"Data de in\u00edcio",//no i18n
"workflow.option.webhookFailure.toDate":"Data de t\u00e9rmino",//no i18n
"crm.custom.field.less.than.equalto":"{0} deve ser menor ou igual a {1}.",//no i18n
	"crm.template.listview.search.no.results":"Nenhum resultado encontrado",//No I18n
	"crm.wf.usage.date.criteria.error.msg":"A data inicial n\u00e3o deve ser posterior \u00e0 data final.",//no i18n
	"crm.label.tag.new":"Nova etiqueta",//No I18n
	"crm.label.enter.tag":"Inserir etiquetas",//No I18n
	"crux.comboBox.max.limit":"Voc\u00ea n\u00e3o pode selecionar mais de {0} {1}.",//NO I18n
	"Administrator":"Administrador",//No I18n
	"Standard":"Padr\u00e3o",//No I18n
	"crm.button.add":"Adicionar",//NO I18n
	"crm.label.users":"Usu\u00e1rios", //NO I18n
  "crm.workflow.alert.roles":"Fun\u00e7\u00f5es", //NO I18n
  "crm.security.groups":"Grupos", //NO I18n
	"crm.label.available" : "Dispon\u00edvel", //NO I18n
	"crm.label.assign.manually" : "Atribuir", //NO I18n
	"crm.globalsearch.option.all": "Todos", //NO I18n
	"webform.status.Active":"Ativo", //NO I18n
	"Inactive":"Inativo", //NO I18n
  "Confirmed":"Confirmado", //NO I18n
  "crm.user.component.unconfirmed":"N\u00e3o confirmado",//no i18n
  "DeletedUser":"Exclu\u00eddo", //NO I18n
  "crm.feed.group.admin":"Administrador", //NO I18n
  "crm.ln.lable.current":"Atual", //NO I18n
	"crm.label.selected": "Selecionado",//NO I18n
	"crm.auditlog.user": "Usu\u00e1rio", //NO I18n
	"cob.role": "Fun\u00e7\u00e3o", //NO I18n
	"zoho.email": "E-mail", //NO I18n
	"Profile": "Perfil", //NO I18n
	"crm.security.group.users.empty": "Nenhum usu\u00e1rio encontrado.", //NO I18n
	"crm.label.picklist.none": "Nenhum", //NO I18n
	"crm.usrpop.non.selected" : "Usu\u00e1rios selecionados",//NO I18n
	"crm.zti.label.user": "Nome do Usu\u00e1rio", //NO I18n
	"crm.label.notSelected" : "N\u00e3o selecionado",//NO I18n
	"AM" : "AM",//NO I18n
	"Call" : "Chamar",//NO I18n
	"crm.phoneNo.Link.Title" : "Chamar usando o Skype",//NO I18n
	"crm.button.cancel" : "Cancelar",//NO I18n
	"crm.button.save" : "Salvar",//NO I18n
	"crm.no.data.found" : "Os dados n\u00e3o foram encontrados.",//NO I18n
	"crm.label.no.options.found" : "N\u00e3o foram encontradas op\u00e7\u00f5es.",//No I18n
	"crm.globalsearch.search.title" : "Pesquisar",//No I18n
	"None" : "Nenhum",//No I18n
	"crm.label.criteria.pattern" : "Padr\u00e3o de Crit\u00e1rios",//No I18n
	"crm.label.edit.criteria.pattern" : "Editar padr\u00e3o",//No I18n
	"criteria.error.alert.brackets.count.mismatch" : "Os par\u00eanteses do padr\u00e3o n\u00e3o s\u00e3o correspondentes.",//No I18n
	"criteria.error.alert.brackets.invalid" : "Par\u00eanteses inv\u00e1lidos em torno do operador da(s) condi\u00e7\u00e3o(\u00f5es).",//No I18n
	"crm.criteria.number.notmatch.check" : "Verifique o padr\u00e3o em {0}.",//No I18n
	"criteria.error.alert.other.params" : "Conte\u00fado inv\u00e1lido neste padr\u00e3o.", //No I18n
	"crm.label.search.for.users": "Usu\u00e1rios selecionados", //NO I18n
	"criteria.error.alert.andor.rowcount.mismatch" : "O padr\u00e3o de crit\u00e9rios n\u00e3o corresponde \u00e0s condi\u00e7\u00f5es escolhidas.", //No I18n
	"criteria.error.alert.critnum.rowcount.mismatch" : "O padr\u00e3o de crit\u00e9rios n\u00e3o corresponde \u00e0s condi\u00e7\u00f5es escolhidas.", //No I18n
	"and" : "e", //No I18n
	"or" : "ou", //No I18n
	"crm.label.or" : "OU", //No I18n
	"crm.label.and" : "E", //No I18n
	"crm.criteria.fieldlabel.valid.check" : "Digite um r\u00f3tulo de campo v\u00e1lido na linha {0} .", //No I18n
	"crm.criteria.condition.valid.check" : "Especifique uma condi\u00e7\u00e3o v\u00e1lida para o {0}.", //No I18n
	"crm.field.valid.check" : "Insira um {0} v\u00e1lido.", //No I18n
	"crm.custom.field.less.than.to" : "<i>De</i> intervalo n\u00e3o pode ser superior a <i>At\u00e9</i> intervalo.", //No I18n
	"crm.alert.label.savepattern" : "Salve um padr\u00e3o antes de alterar os crit\u00e9rios.",//No I18n
	"crm.criteria.max.rowcnt.exceeds" : "Voc\u00ea n\u00e3o pode adicionar crit\u00e9rios adicionais.",//No I18n
	"is" : "\u00e9",//No I18n
	"isn\'t" : "n\u00e3o \u00e9",//No I18n
	"contains" : "cont\u00e9m",//No I18n
	"doesn\'t contain" : "n\u00e3o cont\u00e9m",//No I18n
	"starts with" : "come\u00e7a com",//No I18n
	"ends with" : "termina com",//No I18n
	"is empty" : "est\u00e1 vazio",//No I18n
	"is not empty" : "n\u00e3o est\u00e1 vazio",//No I18n
	"is before" : "\u00e9 antes de",//No I18n
	"is after" : "\u00e9 depois de",//No I18n
	"between" : "entre",//No I18n
	"not between" : "n\u00e3o est\u00e1 entre",//No I18n
	"Today" : "Hoje",//No I18n
	"Tommorow" : "Amanh\u00e3",//No I18n
	"Tommorow Onwards" : "Come\u00e7ando amanh\u00e3",//No I18n
	"Yesterday" : "Ontem",//No I18n
	"Till Yesterday" : "At\u00e9 Ontem",//No I18n
	"Last Month" : "M\u00eas Passado",//No I18n
	"Current Month" : "M\u00eas Atual", //No I18n
	"Next Month" : "Pr\u00f3ximo M\u00eas", //No I18n
	"Last Week" : "Semana Passada", //No I18n
	"Current Week" : "Semana Atual", //No I18n
	"Next Week" : "Pr\u00f3xima Semana", //No I18n
	"Age in Days" : "Idade em Dias", //No I18n
	"Due in Days" : "Vencimento em dias", //No I18n
	"Scheduled" : "Agendado", //No I18n
	"Attended Dialled" : "Discadas atendidas", //No I18n
	"Unattended Dialled" : "Discadas n\u00e3o atendidas", //No I18n
	"Overdue" : "Atrasados", //No I18n
	"Cancelled" : "Cancelado", //No I18n
	"Received" : "Recebidas", //No I18n
	"Missed" : "Perdidas", //No I18n
	"crm.alert.character.not.allowed" : "{0} n\u00e3o \u00e9 permitido", //No I18n
	"crm.condition.in.last" : "nos \u00faltimos", //No I18n
	"crm.zinvoice.dueIn" : "devido em", //No I18n
	"on" : "Em",//No I18n
	"before" : "antes",//No I18n
	"crm.label.general.small.after" : "depois",//No I18n
	"crm.thisweek" : "Esta semana",//No I18n
	"crm.label.this.month" : "Este m\u00eas",//No I18n
	"crm.thisyear" : "Este ano",//No I18n
	"crm.source.user.and.system" : "Usu\u00e1rio e sistema",//No I18n
	"crm.source.user.or.system" : "Usu\u00e1rio ou sistema",//No I18n
	"crm.label.system2" : "Sistema",//No I18n
	"crm.source.user.only" : "Somente pelo usu\u00e1rio",//No I18n
	"crm.source.system.only" : "Somente pelo sistema",//No I18n
	"crm.condition.till.today" : "At\u00e9 hoje",//No I18n
	"game.month.left" : "1 m\u00eas",//No I18n
	"game.months.left" : "{0} meses",//No I18n
	"crm.condition.last.30.days" : "nos \u00faltimos 30 dias",//No I18n
	"crm.condition.last.60.days" : "nos \u00faltimos 60 dias",//No I18n
	"crm.condition.last.90.days" : "nos \u00faltimos 90 dias",//No I18n
	"crm.label.filter.typehere" : "Digite aqui", //No I18N
	"crm.filter.is.not" : "n\u00e3o \u00e9", //No I18n
	"crm.condition.until.now" : "At\u00e9 o momento",//No I18n
	"crm.filter.email.isblocked" : "est\u00e1 bloqueado",//No I18n
	"crm.filter.email.isnotblocked" : "n\u00e3o est\u00e1 bloqueado",//No I18n
	"crm.label.no.results.match" : "Nenhum resultado correspondente",//No I18n
	"crm.label.select.user" : "Clique para selecionar usu\u00e1rios.", //No I18n
	"current.logged.in.user": "Usu\u00e1rio registrado", //NO I18n, //NO i18n
	"crm.security.group": "Grupo", //NO I18n
	"crm.security.role": "Fun\u00e7\u00e3o", //NO I18n //NO i18n
	"Date" : "Data",//No I18n
	"crm.field.valid.decimal.check2" : "As casas decimais para o campo <i>{0}</i> devem ser menores ou iguais a {1}.",//No I18n
	"crm.field.empty.check" : "{0} n\u00e3o pode ficar em branco.",//No I18n
	"crm.label.add.note": "Adicionar nota", //NO I18n
	"crm.label.simply.by": "por", //NO I18n
	"crm.general.addnote": "Adicionar uma observa\u00e7\u00e3o", //NO I18n
	"crm.general.addtitle": "Adicionar t\u00edtulo", //NO I18n
	"crm.label.attach.file": "Anexar Arquivo", //NO I18N
	"crm.button.mass.delete": "Excluir", //NO I18N
	"crm.warning.delete.record": "Tem certeza de que deseja mover \"{0}\" para a lixeira?", //NO I18N
	"crm.label.yes": "Sim", //NO I18N
	"crm.note.view.previous": "Visualizar notas anteriores", //NO I18N
  "of": "de", //NO I18N
	"crm.label.notes": "Notas", //NO I18N
	"crm.note.recent.first": "Primeira recente", //NO I18N
	"crm.note.recent.last": "\u00daltima recente", //NO I18N
	"crm.territory.label.only": "{0} somente", //no i18n
	"crm.select" : "Selecionar",//No I18n
	"crm.button.apply.filter" : "Aplicar Filtro",//No I18n
	"crm.alert.maximum.text.values.contains" : "Voc\u00ea n\u00e3o pode adicionar mais de {0} valores para este campo.",//No I18n
	"PM" : "PM",//No I18n
	//start-cx create form keys
	"crm.duplicate.value.not.allowed": "Valores de duplicatas n\u00e3o s\u00e3o permitidos.",//no i18n
	"crm.duplicate.value.available": "Um {0} existente com o mesmo {1} foi encontrado.",//no i18n
	"crm.duplicate.value.available.multiple": "Mais de um {0} com o mesmo {1} foi encontrado.",//no i18n
	"crm.custombutton.nobuttons.found": "N\u00e3o foi encontrado nenhum bot\u00e3o",//no i18n
	"crm.custombutton.create.newbutton": "Criar bot\u00e3o",//no i18n
	"crm.custombutton.manage.button": "Gerenciar bot\u00f5es",//no i18n
	"crm.custombutton.name": "Nome",//no i18n
	"crm.customize.custombutton.function.desc": "Descri\u00e7\u00e3o",//no i18n
	"crm.custombutton.action": "A\u00e7\u00e3o do bot\u00e3o",//no i18n
	"crm.custombutton.empty.field.values": "Valor vazio encontrado para os seguintes campos:",//no i18n
	"crm.custombutton.proceed.action": "Tem certeza de que ainda deseja prosseguir?",//no i18n
	"zb.common.Cancel": "Cancelar",//no i18n
	"crm.yes.proceed": "Sim, prosseguir.",//no i18n
	"crm.label.module.merge": "Consolidar {0}",//no i18n
	"crm.view.record": "Visualizar {0}",//no i18n
	"crm.tpi.ctiapi.config.choosefile": "Escolher arquivo",//no i18n
	"crm.label.subform.addrows": "Adicionar linha",//no i18n
	"crm.button.save&new": "Salvar e novo",//no i18n
	//end-cx create form keys
	"crm.mb.newversion.msg4" : "Ok, entendi!",//No I18n
	"Jan" : "Jan",//No I18n
	"Feb" : "Fev",//No I18n
	"Mar" : "Mar",//No I18n
	"Apr" : "Abr",//No I18n
	"Jun" : "Jun",//No I18n
	"Jul" : "Jul",//No I18n
	"Aug" : "Ago",//No I18n
	"Sep" : "Set",//No I18n
	"Oct" : "Out",//No I18n
	"Nov" : "Nov",//No I18n
	"Dec" : "Dez",//No I18n
	"crm.label.More" :"Mais", //no i18n

	 "crm.label.unmapped.stages":"N\u00e3o tem conta", //no i18n
	 "crm.wf.summary.label.ShowInstantActions" : "Mostrar mais",//No I18n
	 "crm.wf.summary.label.HideInstantActions" : "Mostrar menos",//No I18n

	 	 //filter related keys-start
"crm.inv.mail.time.two.days.subject":"{0} - {1}",//no i18n
"crm.lead.prediction.tooltip.convert":"SATISFA\u00c7\u00c3O",//no i18n
"crm.lead.prediction.tooltip.score":"PONTUA\u00c7\u00c3O",//no i18n
"Planned":"Planejado",//no i18n
"Invited":"Convidado",//no i18n
"Sent":"Enviado",//no i18n
"Received":"Recebidas",//no i18n
"Opened":"Aberto",//no i18n
"Responded":"Respondido",//no i18n
"Bounced":"Pulado",//no i18n
"Opted\ Out":"Optado por n\u00e3o participar",//no i18n
"crm.filter.label.with.open":"Com {0} aberto",//no i18n
"crm.filter.label.without.open":"Sem abertura {0}",//no i18n
"crm.filter.label.without.any":"Sem nenhum {0}",//no i18n
"crm.filter.label.with.module":"Com {0}",//no i18n
"crm.filter.label.activity.due":"{0} Pendente",//no i18n
"crm.filter.label.activity.done":"{0} Pronto",//no i18n
"Notes":"Notas",//no i18n
"crm.filter.label.notes.added":"Notas adicionadas",//no i18n
"crm.label.filter.email.status":"Status de e-mail mais recente",//no i18n
"crm.label.filter.email.clicked":"clicados",//no i18n
"crm.label.filter.email.responded":"respondeu", //no i18n
"crm.label.filter.email.info":"Filtre os registros com base no status mais recente de seus e-mails enviados/recebidos.",//no i18n
"crm.filter.label.sent":"enviado",//no i18n
"crm.filter.label.not.sent":"n\u00e3o enviado",//no i18n
"crm.filter.label.opened":"aberto",//no i18n
"crm.filter.label.not.opened":"n\u00e3o aberto",//no i18n
"crm.filter.label.received":"recebido",//no i18n
"crm.filter.label.not.received":"n\u00e3o recebido",//no i18n
"crm.filter.label.bounced":"devolvido",//no i18n
"crm.filter.label.opened.not.replied":"aberta e n\u00e3o respondida", //no i18n
"crm.filter.label.any":"Qualquer uma das op\u00e7\u00f5es acima",//no i18n
"crm.zia.config.potential.amount":"Montante de {0}",//no i18n
"Quote\ Stage":"{0} Est\u00e1gio",//no i18n
"crm.module.owner":"Propriet\u00e1rio do {0}",//no i18n
"Potential\ Closing\ Date":"{0} Data de Fechamento",//no i18n
"crm.lead.prediction.likely.convert":"Com probabilidade de converter",//no i18n
"crm.lead.prediction.convert.high":"Alto",//no i18n
"crm.lead.prediction.convert.medium":"M\u00e9dio",//no i18n
"crm.lead.prediction.convert.low":"Baixo",//no i18n
"crm.predictions.feature.label":"Previs\u00e3o",//no i18n
"crm.intelligence.prediction.likelywin":"Com probabilidade de ganhar",//no i18n
"crm.intelligence.prediction.likelylose":"Com probabilidade de perder",//no i18n
"crm.intelligence.prediction.halfchance":"Chance de 50-50",//no i18n
"crm.intelligence.prediction.score":"Pontua\u00e7\u00e3o de previs\u00e3o",//no i18n
"crm.lead.prediction.recent.score":"Pontua\u00e7\u00e3o de previs\u00e3o recente",//no i18n
"crm.intelligence.prediction.lastconv":"\u00faltimas 3 conversas",//no i18n
"crm.intelligence.prediction.recordsfocus":"Registros para foco",//no i18n
"crm.intelligence.prediction.slowmoving":"Movimenta\u00e7\u00e3o lenta",//no i18n
"crm.intelligence.prediction.trend.down":"Caindo recentemente",//no i18n
"crm.label.touched.records":"Registros tocados",//no i18n
"crm.label.untouched.records":"Registros intocados",//no i18n
"crm.label.record.action":"Gravar a\u00e7\u00e3o",//no i18n
"workflow.rule.view.label.Modified":"Modificado:",//no i18n
"crm.label.not.modified":"Sem modifica\u00e7\u00e3o",//no i18n
"crm.label.related.records.action":"A\u00e7\u00e3o relacionada aos registros",//no i18n
"Done":"Pronto",//no i18n
"crm.label.not.done":"N\u00e3o efetuado",//no i18n
"sentiment.model":"Sentimento do e-mail",//no i18n
"sentiment.criteria.count":"Contagem",//no i18n
"sentiment.criteria.percentage":"Porcentagem",//no i18n
"sentiment.criteria.lastmail":"Para o \u00faltimo e-mail",//no i18n
"Chats":"Bate-papos",//no i18n
"Attended":"Participou",//no i18n
"crm.lead.prediction.popup.text":"Quando a \u201cProv\u00e1vel convers\u00e3o\u201d estiver {0}, a pontua\u00e7\u00e3o da previs\u00e3o deve estar entre {1}.",//no i18n
"crm.lead.prediction.popup.final":"Altere o filtro de forma correspondente e tente novamente.",//no i18n
"crm.custom.field.less.than.to1":"O valor inicial do intervalo n\u00e3o pode ser superior ao valor final do intervalo.",//no i18n
"Last\ Activity\ Date":"Data da \u00faltima atividade",//no i18n
"crm.label.vendor.name":"Nome {0}",//no i18n
"hours":"horas",//no i18n
"days":"dias",//no i18n
"weeks":"semanas",//no i18n
"months":"meses",//no i18n
"years":"anos",//no i18n
"crm.label.general.small.after":"depois",//no i18n
"Last\ Week":"Semana Passada",//no i18n
"Last\ Month":"M\u00eas Passado",//no i18n
"crm.module.name":"{0} Nome",//no i18n
"Campaign":"Campanha",//no i18n
"Tasks":"Tarefas",//no i18n
"Calls":"Chamadas",//no i18n
"Events":"Eventos",//no i18n
"sentiment.criteria.wrongcriteria":"O valor dos crit\u00e9rios n\u00e3o pode ser maior do que {0}",//no i18n
"crm.chosen.minimum.input.text":"Insira {0} ou mais caracteres v\u00e1lidos",//no i18n
"crm.intelligence.prediction.trendup":"Mais populares",//no i18n
"crm.intelligence.prediction.trenddown":"Menos populares",//no i18n
"crm.zia.prediction.notrend":"No trend",//no i18n
"crm.zia.prediction.likelihood":"Likelihood Score",//no i18n
"Completed":"Completado" ,//no i18n
"crm.label.success":"Bem-sucedido" ,//no i18n
"crm.label.Failure":"Falha" ,//no i18n
"Both":"Ambos" ,//no i18n
"crm.condition.cannot.empty":"A condi\u00e7\u00e3o n\u00e3o pode ficar vazia.",//no i18n
"crm.condition.last.30.days":"nos \u00faltimos 30 dias",//no i18n
"crm.condition.last.60.days":"nos \u00faltimos 60 dias",//no i18n
"crm.condition.last.90.days":"nos \u00faltimos 90 dias",//no i18n
"crm.sentiment.Positive":"Positivo",//no i18n
"crm.sentiment.Negative":"Negativo",//no i18n
"sentiment.positiveandnegative":"Positivos e negativos",//no i18n
"sentiment.positiveornegative":"Positivos ou negativos",//no i18n
"sentiment.positiveonly":"Somente positivos",//no i18n
"sentiment.negativeonly":"Somente negativos",//no i18n
"crm.sentiment.Neutral":"Neutro",//no i18n
"crm.filters.select.campaign.type":"Selecionar {0} tipo",//no i18n
"crm.filters.select.campaign.status":"Selecionar {0} status",//no i18n
"campaign.Member" : "Membro",//no i18n
	"Service":"Servi\u00e7o",//no i18n
"Activities":"Atividades",//no i18n
"crm.livedesk.pot.nextdays":"Pr\u00f3ximos {0} dias",//no i18n
"Today\ +\ Overdue":"Hoje + Atrasados",//no i18n
"crm.source.user.and.system":"Usu\u00e1rio e sistema",//no i18n
"crm.source.user.or.system":"Usu\u00e1rio ou sistema",//no i18n
"User":"Usu\u00e1rio",//no i18n
"crm.source.user.only":"Somente pelo usu\u00e1rio",//no i18n
"crm.source.system.only":"Somente pelo sistema",//no i18n
"Scheduled":"Agendado",//no i18n
"Attended\ Dialled":"Discadas atendidas",//no i18n
"Unattended\ Dialled":"Discadas n\u00e3o atendidas",//no i18n
"Cancelled":"Cancelado",//no i18n
"crm.filter.email.isblocked":"est\u00e1 bloqueado",//no i18n
"crm.filter.email.isnotblocked":"n\u00e3o est\u00e1 bloqueado",//no i18n
"condition.till.now":"At\u00e9 o momento",//no i18n
"crm.recurring.no.months":"{0} meses",//no i18n
"crm.lead.prediction.tooltip":"Com probabilidade de converter - intervalo de pontua\u00e7\u00e3o",//no i18n
"crm.website.activity":"Atividade do site",//no i18n
"crm.label.By":"Por",//no i18n
"crm.chosen.searching.text":"Pesquisando...",//no i18n
"crm.label.memberstatus.is":"e o status do membro \u00e9",//no i18n
"crm.events.duration":"Dura\u00e7\u00e3o",//no i18n
"crm.title.clear.name":"Limpar",//no i18n
"crm.label.status.is":"e o status \u00e9",//no i18n
"zia.last3.help":"As conversas incluem chamadas, tarefas, {0}, e-mails recebidos, notas, visitas, coment\u00e1rios sociais e solicita\u00e7\u00f5es de suporte do Desk.",//no i18n
"crm.label.tag.related.to":"relacionado a",//no i18n


	//filter related keys-End
	"crm.label.account.created" : "Novo {0} ser\u00e1 criado.",//No I18n
	"crm.krp.no.records.found" : "Nenhuma {0} encontrada",//No I18n
	"crm.module.new" : "Novo {0}",//No I18n
	"crm.label.view" : "Visualizar",//No I18n
	"crm.nsocial.customers" : "Clientes",//No I18n
	"crm.nsocial.open.potential" : "Abrir {0}",//No I18n
	"crm.nsocial.lead.contact" : "{0}/{1}",//No i18n
	"Others" : "Outros",//No i18n
	"crm.field.length.check" : "{0} valor excede o comprimento m\u00e1ximo.", //No I18n
	"crm.lower.now": "agora",//no i18n
	"crm.time.min.ago": "{0} minutos atr\u00e1s",//no i18n
	"crm.time.mins.ago":"{0} minutos atr\u00e1s",//no i18n
	"crm.time.hr.ago": "{0} hora atr\u00e1s",//no i18n
	"crm.time.hrs.ago": "{0} horas. atr\u00e1s", //no i18n
	"AllUsers": "Todos Usu\u00e1rios", //no i18n
	"crm.label.search":"Pesquisar",//no i18n
	"crm.api.filterby":"Filtrar por",//no i18n
	"crm.customview.nofields.found":"--Nenhum campo correspondente--",//no i18n
	"crm.setup.system.ziarecommendation":"Recomenda\u00e7\u00e3o",//no i18n
	"crm.filter.label.all.products":"Todos os {0}",//no i18n
	"crm.filter.label.select.products":"{0} selecionado",//no i18n
	"crm.reviewprocess.smart.filter":"Status do registro processo de revis\u00e3o",//no i18n
	"crm.dashboard.sharing.empty.value.alert":"Escolha um valor.",//no i18n
	"crm.segmentation.segment.score":"Pontua\u00e7\u00e3o do segmento",//no i18n
	"crm.filter.label.in":"baseado em",//no i18n
	"crm.filter.label.and.purchase.in":"e propenso a comprar em",//no i18n
	"crm.filter.label.last.purchased":"e recentemente adquiriu",//no i18n
	"crm.filter.label.a.day":"um dia",//no i18n
	"crm.filter.label.a.week":"uma semana",//no i18n
	"crm.filter.label.a.month":"um m\u00eas",//no i18n
	"crm.cal.custom":"Personalizado",//no i18n
	"crm.mb.field.common.empt":"O valor n\u00e3o pode ficar em branco.",//no i18n
	"crm.chosen.error.loading.text":"Ops, n\u00e3o foi poss\u00edvel carregar seus resultados",//no i18n
	"crm.filter.label.firstbuy":"Primeira vez",//no i18n
	"crm.filter.label.cwbab":"Dependente",//no i18n
	"crm.filter.label.fbt":"Agrupamento",//no i18n
	"crm.filter.label.rebuy":"Repetir",//no i18n
	"crm.filter.label.nextbuy":"Sequ\u00eancia",//no i18n
	"crm.mxnlookup.select" : "Atribuir {0}",//No I18n
	"crm.lookup.chooserecord":"Escolher {0}",//no i18n
	"crm.record.selected":"{0} selecionado",//no i18n
	"crm.module.empty.message" : "N\u00e3o foram achados {0}",//No I18n
	"crm.mxnlookup.selected" : "{0} atribu\u00eddos",//No I18n
	"crm.security.error" : "Voc\u00ea n\u00e3o tem permiss\u00f5es suficientes para realizar essa opera\u00e7\u00e3o. Entre em contato com seu administrador.", //No I18n
	"crm.label.creator.noPermission" : "Permiss\u00e3o Negada", //No I18n
	"crm.segmentation.recency" : "Rec\u00eancia", //No I18n
	"crm.segmentation.frequency" : "Frequ\u00eancia", //No I18n
	"crm.segmentation.monetary" : "Valor monet\u00e1rio", //No I18n
	"crm.smartfilter.related.module.msg" : "Voc\u00ea n\u00e3o pode selecionar mais de tr\u00eas m\u00f3dulos relacionados.", //No I18n
	"crm.smartfilter.related.module.msg1" : "(Ex: E-mail, atividades, notas)", //No I18n
	"crm.smartfilter.related.module.msg2" : "A dura\u00e7\u00e3o n\u00e3o pode ficar vazia", //No I18n
	"crm.label.timeZone": "Fuso Hor\u00e1rio", //NO I18n
	"crm.label.insufficient.privileges": "Privil\u00e9gios insuficientes para realizar esta opera\u00e7\u00e3o. Entre em contato com seu administrador.", //NO I18n
	"crm.filter.header.secton.system": "Filtros definidos pelo sistema", //NO I18N
	"crm.filter.header.secton.fields": "Filtrar por campos", //NO I18N
	"crm.createfield.calcinfo.new" : "Este campo atua como uma calculadora para qualquer express\u00e3o que voc\u00ea inserir.</br> <b>Ex. 20+20</b> produzir\u00e1 <b>40</b> automaticamente",//No i18n
	"crm.lable.read.only" : "Campo somente leitura",//No i18n
	"crm.column.sort.asc" : "Asc",//No I18n
	"crm.column.sort.desc" : "Desc",//No i18n
	"crm.column.unsort" : "Cancelar classifica\u00e7\u00e3o",//No I18n
	"custmr.prtl.notes.shr.to.custmr": "Compartilhar com o cliente", //NO I18N
	"crm.label.edited":"Editado",//no i18n
	"crm.label.edited.on":"Editado em",//no i18n
	"crm.message.limit.exceed": "Somente {0} caracteres s\u00e3o permitidos para {1}.", //NO I18N
	"custmr.prtl.notes.shrd.with.custmr": "Compartilhado com o cliente", //NO I18N
	"crm.button.ok" : "OK",  //NO I18N
	"crm.role.already.selected" : "Esta fun\u00e7\u00e3o j\u00e1 est\u00e1 selecionada", //no i18n
	"crm.user.deleted": "O USU\u00c1RIO FOI EXCLU\u00cdDO",  //NO I18N
	"crm.account.closed": "ESTA CONTA FOI FECHADA",  //NO I18N
	"crm.start.chat": "Iniciar chat",  //NO I18N
	"crm.start.call": "Iniciar uma chamada",  //NO I18N
	"crm.recipient.invalid.email" : "E-mails inv\u00e1lidos encontrados.", //NO I18N
	"crm.recipient.add.recipient" : "Adicionar destinat\u00e1rio adicional", //NO I18N
	"crm.start.video.call": "Iniciar uma chamada de v\u00eddeo",  //NO I18N //ignorei18n_start

	"crm.label.scoring.rules":"Regras de classifica\u00e7\u00e3o",
	"Score":"Pontua\u00e7\u00e3o",
	"Positive Score":"Pontua\u00e7\u00e3o positiva",
	"Negative Score":"Pontua\u00e7\u00e3o negativa",
	"Touch Point Score":"Pontua\u00e7\u00e3o do ponteiro touch",
	"Positive Touch Point Score":"Pontua\u00e7\u00e3o do ponteiro touch positiva",
	"Negative Touch Point Score":"Pontua\u00e7\u00e3o do ponteiro touch negativa",
	"crm.label.type.minutes": "Digite aqui em minutos", //NO I18N

	"is\ OPEN":"est\u00e1 ABERTO",//no i18n
	"is\ WON":"est\u00e1 GANHO",//no i18n
	"is\ LOST":"Est\u00e1 PERDIDO",//no i18n
	"crm.potential.all.open":"Todos os est\u00e1gios em aberto",//no i18n
	"crm.potential.all.won":"Todos os est\u00e1gios ganhos fechados",//no i18n

	"crm.potential.all.lost":"Todos os est\u00e1gios perdidos fechados",//no i18n
	"crm.campaign.member.status" : "Status de membro",//no i18n
	"crm.dashboard.select.type" : "Selecionar {0}",//no i18n
	"crm.campaign.service.status":"Status do servi\u00e7o",//no i18n

	"crm.label.addColumn":"Adicionar Coluna",//no i18n
	"crm.button.clear.filter":"Fechar filtro",//no i18n
	"crm.button.show.filter":"Mostrar filtro",//no i18n
	"crm.las.error.user.maxlimit":"Voc\u00ea pode selecionar no m\u00e1ximo 20 usu\u00e1rios.",//no i18n
	"crm.las.error.picklist.maxlimit":"Voc\u00ea pode selecionar no m\u00e1ximo 20 op\u00e7\u00f5es.",//no i18n

	"crm.fileuploader.message.responseerror": "Falha no upload", //NO I18N
	"crm.storage.create.error":"Nenhum novo registro pode ser criado porque voc\u00ea atingiu seu limite m\u00e1ximo de armazenamento de dados.",//no i18n
"crm.storage.create.error.client":"Nenhum novo registro pode ser criado porque seu administrador atingiu o limite m\u00e1ximo de armazenamento. Entre em contato com {0} para resolver este problema.",//no i18n
"crm.storage.avail.info":"({0} restante de {1})",//no i18n
"crm.storage.error.key.manage":"Gerencie seu armazenamento de dados",//no i18n
"Records":"Registros",//no i18n
"crm.workflow.alert.additional.recipients" : "Destinat\u00e1rios Adicionais", //NO I18N
"crm.workflow.alert.type.otherEmails" : "Use v\u00edrgulas para separar os endere\u00e7os de e-mail adicionais.", //NO I18N
"crm.related.contact.account" : "{0} relacionado a {1}",//No I18n
	"crm.allcontact.show" : "Todos{0}",//No I18n
	"crm.button.mass.show" : "Exibir",//No I18n
	"crm.msg.custom.view.not.replied" : "Mensagens n\u00e3o respondidas", //NO I18N
	"crm.msg.custom.view.replied" : "Mensagem respondidas",//NO I18N
	"crm.workflow.select.recipients" : "Destinat\u00e1rios", //NO I18N
	"crm.custom.module.no.profile.selected.alert1":"Selecione pelo menos um perfil.",//NO I18N
	"crm.auto.enrich.remove.default.profile" : "Desculpe, mas n\u00e3o \u00e9 poss\u00edvel remover um perfil padr\u00e3o.",//NO I18N
	"crm.inv.label.add.emails" : "Adicionar e-mails", //NO I18N
	"crm.prediction.analytics.filter.year":"Ano passado",//no i18n
	"Previous\ FY":"Ano Fiscal Anterior",//no i18n
	"Current\ FY":"Ano Fiscal Atual",//no i18n
	"Next\ FY":"Pr\u00f3ximo Ano Fiscal",//no i18n
	"Current\ FQ":"Trimestre Fiscal Atual",//no i18n
	"Next\ FQ":"Pr\u00f3ximo Trimestre Fiscal",//no i18n
	"Previous\ FQ":"Trimestre Fiscal Anterior",//no i18n
	"crm.picklist.sample.text":"Texto de amostra",//no i18n
	"crm.more.colors":"Mais cores",//no i18n
	"crm.button.back.alone":"Voltar",//no i18n
	"crm.field.label.email":"E-mail",//no i18n
"crm.zia.nba.feature.label":"Pr\u00f3xima melhor a\u00e7\u00e3o",//no i18n
"Meeting":"Reuni\u00e3o",//no i18n
"Tomorrow":"Amanh\u00e3",//no i18n
"crm.gdpr.notavailable.field":"Indispon\u00edvel",//no i18n
	"crm.setup.system.ziasimilarity":"Recomenda\u00e7\u00e3o de semelhan\u00e7a",//no i18n
	"crm.gdpr.notavailable.field":"Indispon\u00edvel",//no i18n
	"crm.label.simply.in":"em",//no i18n
	"crm.filter.label.all.products":"Todos os {0}",//NO I18N
	'crm.zia.nbx.filter.due' : "Due" ,//NO I18N
	"abm.segment": "Segmento", // NO I18N
	"abm.segment.names": "Nomes dos segmentos", // NO I18N
	"abm.abm.segment.name": "Nome do segmento do ABM", // NO I18N
	"abm.segmentation.techniques": "T\u00e9cnicas de segmenta\u00e7\u00e3o do ABM", // NO I18N
	"abm.filters": "Filtros do ABM", // NO I18N
	"abm.filters.info": "Esses filtros mostrar\u00e3o informa\u00e7\u00f5es com base no {0} associado ao {1}.", // NO I18N
	"abm.rfm.label": "R\u00f3tulo do RFM", // NO I18N
	"abm.firmographics.label": "R\u00f3tulo firmogr\u00e1fico" ,// NO I18N
	"abm.recommendation.label": "R\u00f3tulo de recomenda\u00e7\u00e3o", // NO I18N
	"abm.voc.label": "R\u00f3tulo do VOC", // NO I18N
	"abm.engagement.label": "R\u00f3tulo de engajamento", // NO I18N
	"abm.overall.score": "Pontua\u00e7\u00e3o geral", // NO I18N
	"abm.mood.score": "Pontua\u00e7\u00e3o de humor", // NO I18N
	"abm.value.score": "Pontua\u00e7\u00e3o de valor", // NO I18N
	"abm.engagement.score": "Pontua\u00e7\u00e3o de engajamento", // NO I18N
	"abm.rfm.score": "Pontua\u00e7\u00e3o do RFM", // NO I18N
	"crm.column.list.max.group.limit" : "", //no i18n
	"crm.customview.pin.column":"Fixar coluna",//no i18n
"crm.customview.unpin.column":"Desafixar coluna",//no i18n
	"zia.similarity.smartfilter.score":"Pontua\u00e7\u00e3o de similaridade",//no i18n
	"zia.similarity.smartfilter.records":"{0} semelhante do",//no i18n
	"zia.similarity.smartfilter.records.search":"Mostrar {0} semelhante a",//no i18n
	"crux.smartfilter.multiselect.maximum.selected":"Voc\u00ea n\u00e3o pode selecionar mais de {0} {1}",//no i18n
	"crux.custom.field.greater.than.equalto":"{0} deve ser maior ou igual a {1}.",
	"crux.users.selected.plural" : "{0} usu�rios selecionados.",
	"crux.user.selected.singular" :"{0} usu�rio selecionado.",
	"crux.criteria.empty.secondaryfield.module" : "Sem correspond�ncia do campo {0} encontrada no m�dulo {1}",
	"crux.criteria.empty.secondaryfield" : "Nenhum outro campo {0} dispon�vel para compara��o, insira um valor para comparar.",
	"crux.logged.in.role.definition" : "A fun��o do usu�rio que inicia as a��es de registro", //NO I18N
	"crux.max.limit.unselect" : "Você não pode desselecionar mais de {0} {1}.", //NO I18N
	"crux.existing.tag" : "\u201c{0}\u201d já foi selecionado", //NO I18N
	"crm.label.true" : "Verdadeiro",//NO I18N
	"crm.label.false" : "Falso",//NO I18N
	"crm.record.locked" : "Bloqueado",//NO I18N
	"crm.filter.number.range":"valores de {0}-{1}",//no i18n
	"crm.smartfilter.picklist.options.msg":"Voc\u00ea n\u00e3o pode selecionar esta op\u00e7\u00e3o para mais de 10 valores selecionados.",//no i18n
	"crm.chosen.maximum.campaigns.selected":"N\u00e3o \u00e9 poss\u00edvel selecionar mais de 5 {0}",//no i18n
	"crux.smartfilter.multiselect.maximum.selected":"Voc\u00ea n\u00e3o pode selecionar mais de {0} {1}",//no i18n
	"crm.best.time.column.label":"Melhor hor\u00e1rio para o contato",//no i18n
	"crm.competitoralert.mentioned.in.email":"Mencionado no <b>e-mail</b>",//no i18n
	"crm.competitoralert.with.sentiment":"Com sentimento",//no i18n
	"crm.competitoralert.name.error":"Forne\u00e7a um valor v\u00e1lido para o nome do concorrente.",//no i18n
	"crm.competitoralert.date.error":"Forne\u00e7a uma data v\u00e1lida para men\u00e7\u00e3o do concorrente por e-mail.",//no i18n
	"crm.competitoralert.sentiment.error":"Selecione uma op\u00e7\u00e3o v\u00e1lida para o sentimento do concorrente.",//no i18n
	"crm.competitor.name":"Concorrente",
"crm.lookup.advance.error.msg" : "Algo deu errado ao aplicar o filtro. Atualize a p\u00e1gina e tente novamente.",
	"Contact\ Name" : "Nome {0}",
	"Lead\ Name" : "Nome {0}",
	"-None-" : "Nenhum",
	"January" : "Janeiro",
	"February":"Fevereiro",
	"March":"Mar\u00e7o",
	"April":"Abril",
	"May":"Maio",
	"June":"Junho",
	"July":"Julho",
	"August":"Agosto",
	"September":"Setembro",
	"October":"Outubro",
	"November":"Novembro",
	"December":"Dezembro",
	"crm.label.add.tags":"Adicionar etiquetas",//no i18n
	"crm.confirm.deassociate":"Desatribuir",//no i18n
	// Image Upload Open
	"crm.label.browse.files":"Procurar arquivos",//no i18n
	"crm.label.lowercase.or":"ou",//no i18n
	"crm.image.header":"Imagem",//no i18n
	"crm.image.n.n":"Raz\u00e3o N:N",//no i18n
	"crm.image.2.2":"Raz\u00e3o 2:2",//no i18n
	"crm.image.4.3":"Raz\u00e3o 4:3",//no i18n
	"crm.image.16.9":"Raz\u00e3o 16:9",//no i18n
	"crm.image.height.width.swap":"Altura e largura da troca",//no i18n
	"crm.image.rotate.image":"Girar",//no i18n
	"crm.label.lowercase.of":"de",//no i18n
	"crm.image.empty.name.placeholder" : "Insira um nome",
	"crm.image.crop.and.rotate" : "Cortar e girar",
	"crm.image.description" : "Adicionar descri\u00e7\u00e3o...",
	"crm.image.actual.size" : "Tamanho real",
	"crm.image.reset" : "Reiniciar",
	"crm.image.width.px" : "px",
	"crm.view.attachment.download":"Download",//no i18n
	"crm.label.edit":"Editar",//no i18n
	"crm.label.close":"Fechar",//no i18n
	"crm.label.any":"Qualquer",
	"crm.image.description":"Adicionar descri\u00e7\u00e3o...",//no i18n
	"crm.image.zoom.in":"Ampliar",//no i18n
	"crm.image.zoom.out":"Reduzir",//no i18n
	"crm.label.lowercase.of":"de",//no i18n
	"crm.image.desc.maxsize":"A descri\u00e7\u00e3o n\u00e3o deve exceder 255 caracteres.",//no i18n
	"crm.image.name.maxsize":"O nome n\u00e3o deve exceder 100 caracteres.",//no i18n
	"crm.fileuploader.message.totalfilesizeexceeded":"O tamanho total do arquivo excede o limite permitido de {0}.",//no i18n
	"crm.fileuploader.message.maxfilesexceeded":"Voc\u00ea pode fazer o upload de no m\u00e1ximo {0} arquivos apenas.",//no i18n
	"crm.fileuploader.message.maxfilesexceeded1":"Voc\u00ea pode anexar no m\u00e1ximo {0} arquivos apenas.",//no i18n
	"crm.ImageuploadField.size.limit":"O tamanho total da(s) imagem(ns) excede o limite permitido de {0} MB.",//no i18n
	"crm.attachment.imageUploadField.Minlen.check":"Voc\u00ea pode escolher apenas 1 imagem",//no i18n
	"crm.image.crop.error":"N\u00e3o \u00e9 poss\u00edvel cortar a imagem, tente novamente!",//no i18n
	"crm.attach.here":"aqui.",//no i18n
	"crm.image.unsupported.corrupted.single":"A foto que voc\u00ea est\u00e1 tentando enviar est\u00e1 corrompida.",//no i18n
	"Browse":"Procurar",//no i18n
	"crm.imageupload.failure":"Algumas imagens n\u00e3o puderam ser carregadas. Tente novamente mais tarde.",//no i18n
	"zc.editor.width":"Largura",//no i18n
	"ze.editor.height":"Altura",//no i18n
	"crm.label.delete":"Excluir",//no i18n
	"crm.image.error.gif":"A imagem GIF n\u00e3o pode ser cortada.",//no i18n
	"crm.fileuploader.message.morefiles":"{0} outros arquivos",//no i18n
	"crm.fileuploader.message.invalidfileType1":"O arquivo {0} n\u00e3o \u00e9 compat\u00edvel.",//no i18n
	"crm.fileuploader.message.invalidfileType2":"Os arquivos {0} e {1} n\u00e3o s\u00e3o compat\u00edveis.",//no i18n
	"crm.attach.upload.sizelimit":"Limite total de tamanho",//no i18n
	
	
	
	
	"crm.fileuploader.message.maxfilesexceeded" : "Voc\u00ea pode fazer o upload de no m\u00e1ximo {0} arquivos apenas.",
	"crm.file.upload.maxlength.exceeds" : "Voc\u00ea pode fazer upload de apenas um arquivo no {0}."


}
